$is-codecombat: true
#segment-check-view
  .class-code-input-group
    display: flex
    flex-direction: row
    align-items: center
    .class-code-input
      text-align: center

    .glyphicon
      width: 0
      line-height: 40px
      font-size: 30px

  .parent_birthdate
    font-size: 11pt
    margin-top: 20px

  .extra
    .wwcs
      color: #0085dd
      cursor: pointer
      &:hover + .wwqr
        opacity: 1
        
    .wwqr
      display: block
      margin: auto
      opacity: 0

  &.student
    .modal-body-content
      padding: 0

    .modal-student-contents
      display: flex
      flex-direction: column
      justify-content: space-around
      align-items: center
      height: 392px // Hack: Hard code due to Chrome flexbox 100% height propagation.
      width: 100%

    .class-code-section
      width: 80%
      text-align: left
      font-family: Open Sans
      font-style: normal
      font-weight: bold
      font-size: 22px
      line-height: 30px
      color: #232323
      
      label
        font-weight: 300
        font-size: 18px
        line-height: 25px
      
      .render
        font-weight: normal
        font-size: 24px
        line-height: 33px

      .ask-teacher
        font-size: 16px
        line-height: 22px
      
      input
        width: 300px
        text-align: left

      .class-code-input-group
        justify-content: space-between
    
    .input-with-glyph
      display: flex

    .input-with-glyph input
      margin-right: 10px

    .divider-line
      border: 2px solid #ff6978
      width: 85%

    .play-now-section
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      width: 80%

      & > *
        flex: 1

      & > :first-child
        flex: 2
        padding-right: 100px
        text-align: left
        font-family: Open Sans
        font-style: normal
        font-weight: bold
        font-size: 22px
        line-height: 30px
        color: #232323

    .footer-signin
      font-family: Open Sans
      font-size: 16px
      color: #232323
      margin-bottom: -20px
      & a
        color: #232323
        text-decoration: underline
