$is-codecombat: true;

/* These styles are global. This is required so bootstrap.... :( */

@import "app/styles/bootstrap/variables";
@import "app/styles/mixins";
@import "app/styles/style-flat-variables";
@import "app/styles/component_variables.scss";

#main-nav.navbar {
  background: white;

  ::v-deep .emoji-flag {
    font-size: 30px
  }

  // Add dark mode styles
  &.dark-mode {

    &,
    .dropdown-menu {
      background: $dark-grey-2;
    }

    .dropdown-menu {
      h5 {
        color: white;
      }

      ::v-deep {
        li {
          background: $dark-grey-2;

          a {
            color: white;
            background: $dark-grey-2;

            &:hover {
              background: lighten($dark-grey-2, 10%);
            }
          }
        }
      }
    }

    p,
    li,
    span,
    a,
    button,
    div {
      color: white;
    }

    .nav {
      >li {
        >a {
          color: white;
        }
      }
    }
  }

  .dropdown-menu.text-wide {
    width: 400px;
    @media (max-width: 991px) {
      width: 100%
    }
  }

  .dropdown-menu,
  ::v-deep .language-dropdown {
    @media (max-width: 991px) {
      li>a {
        color: $dark-grey-2;
      }
    }
  }

  p,
  li,
  span,
  a,
  button,
  div {
    font-family: $main-font-family;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }

  .navbar-brand {
    #logo-img {
      max-height: 41px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .navbar-collapse {
    max-height: min(600px, 50vh);

    .nav.navbar-nav {
      max-width: 100%;
    }
  }

  .language-dropdown {
    left: unset;
    right: 0;

    @media screen and (min-width: $screen-md-min) {
      max-height: calc(100vh - 80px);
      overflow-y: scroll;
    }
  }

  .header-container {
    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .navbar-header {
        flex-grow: 0;
      }

      .navbar-collapse {
        flex-grow: 1;
      }
    }
  }

  .navbar-collapse {
    @media (min-width: 992px) {
      display: flex !important;
      justify-content: space-between;
      align-items: center;

      .nav.navbar-nav {
        max-width: 100%;
        flex-grow: 1;
        display: flex;
        justify-content: center;

        &.loggedin {
          flex-grow: 0;
          position: relative;
          padding-right: 6px;
          margin-right: 6px;

          &:after {
            border-right: 1px solid $light-grey-2;
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 27px;
          }
        }
      }

      .right {
        margin-left: auto;
        display: flex;
        align-items: center;

        >.login-buttons {
          position: relative;
          padding-right: 6px;
          margin-right: 6px;

          &:after {
            border-right: 1px solid $light-grey-2;
            content: '';
            position: absolute;
            right: 0;
            top: 5px;
            height: calc(100% - 10px);
          }
        }
      }
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    .dropdown-hover:hover {
      &>ul {
        /* Allows for mouse over to expand dropdown */
        display: unset;
      }
    }
  }

  .nav {
    >li {
      >a {
        color: $dark-grey-2;
        text-align: center;
        font-family: $main-font-family;
        font-style: normal;
        text-shadow: none;
      }
    }
  }

  li {
    >.text-p>.text-description {
      font-size: 12px;
      color: $dark-grey-2;
      white-space: normal;
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.3s ease-in-out;
    }

    &:hover>.text-p>.text-description {
      max-height: 100px;
    }
  }

  .login-buttons {
    li {
      margin: auto 10px;
    }
  }

  .img-circle {
    border: $gold 8px solid;
    width: 98px;
    height: 98px; // Includes the border
  }

  .img-circle-small {
    border: $gold 3px solid;
    width: 33px;
    height: 33px;
  }

  // For teacher avatars
  .border-burgundy {
    border-color: $navy;
  }

  .border-navy {
    border-color: $navy;
  }

  span.unreadMessage {
    width: 5px;
    height: 5px;
    position: absolute;
    top: 10px;
    left: 45px;
    border-radius: 50%;
    background-color: $yellow;
    box-shadow: 0 0 2px 2px $yellow;
  }

  .dropleft {
    .announcement-nav {
      position: absolute;
      left: auto;
      right: 100%;
      top: 0;
    }

    .rotate-left {
      transform: rotate(90deg);
    }
  }

  span.unread {
    width: 1.2em;
    height: 1.2em;
    margin-left: 1em;
    line-height: 1.2em;
    border-radius: 50%;
    background-color: $yellow;
    color: white;
    display: inline-block;
    margin-left: 0.5em;
  }

}

nav#main-nav.navbar.dark-mode {
  background-color: #352C20;

  .nav > li > a {
    color: #FCBB00;

    &:hover {
      color: #FF39A6;
    }
  }

  .dropdown-menu {
    background-color: white;
  }

  .account-dropdown-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tecmilenio-logo, .tarena-logo {
  height: 36px;
}

.mto-logo {
  height: 35px;
}

.code-ninjas-logo {
  height: 41px;
  max-height: 100%;
}

